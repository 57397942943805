import React from 'react';

// Packages
import { Card } from 'antd';
import ReactPlayer from 'react-player';

const VideoPlayer = (props) => {
	const { videoInfo } = props;

	return (
		<div className='video-container'>
			<ReactPlayer
				controls
				url={videoInfo.url}
				width={'100%'}
				height={'100%'}
				playing
			/>
			{videoInfo.title.length !== 0 &&
				<div className='video-description-container'>
					<Card className='video-description-card'>
						<Card.Meta
							title={videoInfo.title}
						/>
						<p style={{ marginBottom: 0 }}>{videoInfo.description}</p>
					</Card>
				</div>
			}
		</div>
	);
}

export default VideoPlayer;
