// Packages
import { Layout } from 'antd';

import version from '../../version.json';

const App = () => {
	return (
		<Layout.Footer className='footer'>
			Copyright &copy; { new Date().getFullYear() } Percepthor - { version.version_name } - { version.version_date } - Runtime: { process.env.REACT_APP_RUNTIME }
		</Layout.Footer>
	);
};

export default App;
