// Packages
import i18next from '../translations/i18next';

export const videosData = [
	{
		title: i18next.t('videos.issue.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/censo-incidencia-13-09-2023.mp4',
		image: 'censo-incidencia',
		duration: '0:41',
		description: i18next.t('videos.issue.description')
	},
	{
		title: i18next.t('videos.appConfig.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/config-app-13-09-2023.mp4',
		image: 'config-app',
		duration: '0:38',
		description: i18next.t('videos.appConfig.description'),
	},
	{
		title: i18next.t('videos.payload.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/censo-completo-13-09-2023.mp4',
		image: 'censo-completo',
		duration: '2:02',
		description: i18next.t('videos.payload.description'),
	},
	{
		title: i18next.t('videos.refrigerators.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/captura-enfriadores-13-09-2023.mp4',
		image: 'captura-enfriadores',
		duration: '0:49',
		description: i18next.t('videos.refrigerators.description'),
	},
	{
		title: i18next.t('videos.login.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/login-13-09-2023.mp4',
		image: 'login',
		duration: '0:40',
		description: i18next.t('videos.login.description'),
	},
	{
		title: i18next.t('videos.intelligence.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/intelligence-13-09-2023.mp4',
		image: 'intelligence',
		duration: '4:43',
		description: i18next.t('videos.intelligence.description'),
	},
	{
		title: i18next.t('videos.counter.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/enfriadores-racks-13-09-2023.mp4',
		image: 'enfriadores-racks',
		duration: '1:55',
		description: i18next.t('videos.counter.description'),
	},
	{
		title: i18next.t('videos.offline.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/censo-offline-03-10-2023.mp4',
		image: 'censo-offline',
		duration: '2:01',
		description: i18next.t('videos.offline.description'),
	},
	{
		title: i18next.t('videos.segmentation.title'),
		url: 'https://service.percepthor.com/api/uploads/tutorials/segmentacion-04-10-2023.mp4',
		image: 'segmentacion',
		duration: '3:45',
		description: i18next.t('videos.segmentation.description'),
	}
];
