import React from 'react';

// Packages
import { List, Space, Typography } from 'antd';
import { CaretRightOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// Utils
import { videosData } from '../../utils/videos_data';

const PlayList = (props) => {
	const { t } = useTranslation();

	const { videoInfo, setVideoInfo } = props;

	return (
		<div className='playlist'>
			<List
				className='video-description-card'
				style={{ maxHeight: '661px', overflowY: 'auto', backgroundColor: '#fff' }}
				header={<Typography.Title level={3}>{t('playlist')}</Typography.Title>}
				bordered
				dataSource={videosData}
				renderItem={(item, index) => (
					<List.Item 
						style={{ backgroundColor: videoInfo?.title === item.title && '#f2f2f2', cursor: 'pointer' }}
						onClick={() => setVideoInfo(item)}
					>
						<Space>
							{videoInfo?.title === item.title
								?	<CaretRightOutlined style={{ marginRight: '23px', fontSize: '17px' }} />
								:	<Typography.Text style={{ marginRight: '30px', fontSize: '17px' }}>{index + 1}</Typography.Text>
							}
						</Space>
						<List.Item.Meta
							avatar={<img style={{ height: '50px' }} src={process.env.PUBLIC_URL + `/assets/video_covers/${item.image}.png`} />}
							title={item.title}
							description={<Typography.Text ><ClockCircleOutlined style={{ marginRight: '7px' }} />{item.duration}</Typography.Text>}
						/>
					</List.Item>
				)}
			/>
		</div>
	)
}

export default PlayList;
