import React, { useState } from 'react';

// Pckages
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

// Components
import PlayList from './PlayList';
import VideoPlayer from './VideoPlayer';

const Tutorials = () => {
	const { t } = useTranslation();

	const [videoInfo, setVideoInfo] = useState({ title: '', url: '', image: '', duration: '', description: '' });

	return (
		<div>
			<Typography.Title level={2}>{t('landing.title')}</Typography.Title>
			<Typography.Text>{t('landing.instruction')}</Typography.Text>
			<Row  className='tutorials-container'>
				<Col xs={24} lg={16}>
					<VideoPlayer videoInfo={videoInfo} />
				</Col>
				<Col xs={24} lg={8}>
					<PlayList videoInfo={videoInfo} setVideoInfo={setVideoInfo} />
				</Col>
			</Row>
		</div>
	)
}

export default Tutorials;
