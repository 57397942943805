export const ENGLISH_TRANSLATIONS = {
	menu: {
		account: 'Account',
		return: 'Return to menu',
		logOut: 'Log Out'
	},
	landing: {
		title: 'Training',
		instruction: 'In this dashboard, you can view training videos to learn how to perform various actions in the mobile application.'
	},
	
	// VIDEOS INFO
	playlist: 'Playlist',
	videos: {
		issue: {
			title: 'Issue',
			description: 'Tutorial to perform an issue.'
		},
		appConfig: {
			title: 'App Configuration',
			description: 'Tutorial to configure the language and theme.'
		},
		payload: {
			title: 'Complete Payload',
			description: 'Tutorial to perform a complete payload.'
		},
		refrigerators: {
			title: 'Capture of Refrigerators',
			description: 'Tutorial for taking photos of refrigerators in an appropriate manner.'
		},
		login: {
			title: 'Log In',
			description: 'Tutorial to log in with your username and password.'
		},
		intelligence: {
			title: 'Intelligence',
			description: 'Tutorial to use the Intelligence process, where the promoter performs a payload, and the manager evaluates it, potentially requiring a redo if necessary.'
		},
		counter: {
			title: 'Addition and Removal of Racks and Refrigerators',
			description: 'Tutorial to change the quantity of racks or refrigerators to capture.'
		},
		offline: {
			title: 'Offline Payload',
			description: 'Tutorial to conduct a payload in offline mode (without an internet connection).'
		},
		segmentation: {
			title: 'Segmentation Module',
			description: 'Tutorial to perform a payload in Segmentation Module.'
		}
	}
};
