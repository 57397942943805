import { combineReducers } from 'redux';

// Reducers
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';

export default combineReducers ({
	auth: authReducer,
	organization: organizationReducer
});
