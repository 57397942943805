export const SPANISH_TRANSLATIONS = {
	menu: {
		account: 'Cuenta',
		return: 'Regresar al menú',
		logOut: 'Cerrar sesión'
	},
	landing: {
		title: 'Capacitación',
		instruction: 'En este dashboard se pueden ver los videos de capacitación para aprender a realizar diversas acciones en la aplicación móvil.'
	},
	
	// VIDEOS INFO
	playlist: 'Lista de Reproduccion',
	videos: {
		issue: {
			title: 'Censo con Incidencia',
			description: 'Tutorial sobre cómo realizar un censo con una incidencia.'
		},
		appConfig: {
			title: 'Configuración de la App',
			description: 'Tutorial para configurar el idioma y el tema.'
		},
		payload: {
			title: 'Censo Completo',
			description: 'Tutorial para realizar un censo completo'
		},
		refrigerators: {
			title: 'Captura de Enfriadores',
			description: 'Tutorial para tomar fotos de los enfriadores de manera adecuada.'
		},
		login: {
			title: 'Log In',
			description: 'Tutorial para iniciar sesión con tu usuario y contraseña.'
		},
		intelligence: {
			title: 'Intelligence',
			description: 'Tutorial para realizar el proceso de Intelligence, donde el promotor realiza un censo y el jefe de territorio lo evalúa, pudiendo requerir repetirlo si es necesario.'
		},
		counter: {
			title: 'Alta y Baja de Racks y Enfriadores',
			description: 'Tutorial para cambiar la cantidad de racks o enfriadores a capturar.'
		},
		offline: {
			title: 'Censo Offline',
			description: 'Tutorial para realizar un censo en modo offline (fuera de línea o sin conexión a internet).'
		},
		segmentation: {
			title: 'Modulo Segmentation',
			description: 'Tutorial para realizar un censo en el Módulo de Segmentación.'
		}
	}
};
