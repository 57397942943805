import Axios from 'axios';

import {
	ORG_INFO_GET,
	ORG_INFO_ERROR
} from './types';

export const get_organization_info = (org_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/organizations/${org_id}/info`;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: ORG_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		dispatch({
			type: ORG_INFO_ERROR,
			payload: { type: 'org_info', msg: err.message }
		})
	})
}
