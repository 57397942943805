import {
	ORG_INFO_GET,
	ORG_INFO_ERROR
} from '../actions/types';

const initialState = {
	organization_info: {},
	organizations_errors: {}
}

export default function organizationReducer(state = initialState, action){
	switch(action.type){
		case ORG_INFO_GET:
			return {
				...state,
				organization_info: action.payload
			}
		case ORG_INFO_ERROR:
			return {
				...state,
				searching_organizations: false,
				organizations_errors: {
					...state.organizations_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
