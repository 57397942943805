import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Dropdown, Layout, Space, Typography } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// Actions
import { auth_token_remove } from '../../actions/authActions';
import { get_organization_info } from '../../actions/organizationActions';

const Header = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { organization_info } = useSelector(state => state.organization);

	useEffect(() => {
		dispatch(get_organization_info(user.organization));
	}, []);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(auth_token_remove());
	}

	const items = [
		{
			label: <a href={process.env.REACT_APP_LOGIN + '/account'}><i className='bi bi-person-circle dp-item-icon'></i> {t('menu.account')}</a>,
			key: '0',
		},
		{
			label: <a href={process.env.REACT_APP_LOGIN + '/links'}><i className='bi bi-grid dp-item-icon'></i> {t('menu.return')}</a>,
			key: '1',
		},
		{
			type: 'divider',
		},
		{
			label: <a onClick={(e) => handleLogout(e)}><i className='bi bi-box-arrow-right dp-item-icon'></i> {t('menu.logOut')}</a>,
			key: '3',
		}
	];

	return (
		<Layout.Header className='header'>
			<div className='header-brand'>
				<img className='header-brand-logo' src={process.env.PUBLIC_URL + '/assets/percepthor-logo.png'} />
				<Typography.Text className='header-brand-text'>
					Percepthor - {organization_info?.name}
				</Typography.Text>
			</div>
			<div className='header-dropdown'>
				<Dropdown
					menu={{ items }}
					trigger={['click']}
					placement='bottom'
				>
					<a onClick={(e) => e.preventDefault()}>
						<Space>
							<i className='bi bi-person-circle'></i>{user.username}
							<CaretDownOutlined />
						</Space>
					</a>
				</Dropdown>
			</div>
		</Layout.Header>
	);
};

export default Header;
