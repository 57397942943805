import React from 'react';

// Packages
import { BrowserRouter as  Router, Routes, Route  } from "react-router-dom";
import { Layout } from 'antd';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Components
import Authentication from './components/main/Authentication';
import Header from './components/main/Header';
import Footer from './components/main/Footer';
import NotFound from './components/main/NotFound';
import Tutorials from './components/tutorials/Tutorials';

// Actions
import { user_token_check } from './actions/authActions';

user_token_check (store);

const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<Layout className='layout'>
					<Header />

					<Layout.Content className='content'>
						<Routes>
							<Route path='/auth' element={ <Authentication /> } exact />

							<Route path='/' element={ <Tutorials /> } />

							 {/* Other */}
							<Route path='*' element={ <NotFound /> } />
						</Routes>
					</Layout.Content>
					
					<Footer />
				</Layout>
			</Router>
		</Provider>
	);
};

export default App;
